import React from "react";
import {IonCard, IonCardContent} from '@ionic/react'

import { RESPONSE_FAILED, RESPONSE_OUTDATED, RESPONSE_SUCCEEDED } from "../constants/config";
import i18n from "../i18n/i18n";
import { GainState } from "../interfaces";


const SucceedMessage: React.FC <GainState> = ({score}) => {
    return (
        <div style={{textAlign: 'center'}}>
            <div style={{fontSize: 18}}>{i18n.t('gain.congrats')}</div>
            <div>
                <span style={{fontSize: 50, color: 'orange'}}>{score}</span>
                <span style={{fontSize: 12}}>pt(s)</span>
            </div>
        </div>
    )
}

const FailMessage: React.FC <GainState> = ({score}) => {
    return (
        <div style={{fontSize: 18, textAlign: 'center'}}>{i18n.t('gain.sorry')}</div>
    )
}

const OutdatedMessage: React.FC = () => {
    return (
        <div style={{fontSize: 18, textAlign: 'center'}}>
            {i18n.t('gain.outdated')}
        </div>
    )
}


const GainText: React.FC <GainState> = (props) => {
    const {result, score} = props

    return (
        <IonCard>
            <IonCardContent>
                {
                    (() => {
                        switch (result) {
                            case RESPONSE_SUCCEEDED:
                                return <SucceedMessage score={score} />;
                            case RESPONSE_FAILED:
                                return <FailMessage score={score} />;
                            case RESPONSE_OUTDATED:
                                return <OutdatedMessage />;
                            default:
                                return null
                        }
                    })()
                }
            </IonCardContent>
        </IonCard>
    )
}

export default GainText;