import React from 'react'
import { IonGrid, IonCol, IonRow, IonText } from '@ionic/react';
import Lottie from "lottie-react";

import i18n from '../i18n/i18n'
import WaitingAnimation from '../assets/lotties/waiting.json'


const WaitChallenge = () => {
    return (
        <IonGrid id="wait-challenge" fixed>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
                <IonCol className='ion-text-center'>
                    <Lottie animationData={WaitingAnimation} loop={true} />
                    <IonText color='light' style={{fontWeight: 'bold', fontSize: 13}}>
                        {i18n.t('waitChallenge')}...
                    </IonText>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default WaitChallenge