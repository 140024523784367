/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {IonRow, IonItem, IonCol, IonLabel, IonCheckbox} from '@ionic/react'

import { emitter } from '../helpers';
import { ProposalsProps, ProposalProps } from '../interfaces';
import { RESPONSE_PENDING } from "../constants/config";
import { CHALLENGE_ANSWER_CHOICE, WS_EMIT_MESSAGE, HTTP_EMIT_MESSAGE } from '../constants/config';
import { SHOW_ANSWER_KEY } from '../constants/ws_event';

const Proposal: React.FC<ProposalProps> = (props) => {
    const { item, add, del, state, cid, uid } = props

    const [checked, setChecked] = React.useState<boolean>(false)
    const [ok, setOk] = React.useState<boolean>(false)
    const [ok_answers, setOkAnswers] = React.useState<string[]>([])

    const {ref, label} = item
    const action = {ref: ref, cid: cid, uid: uid}

    const select = (val: string) => {
        const payload = {...action, ...{state: true}}
        emitter.emit(HTTP_EMIT_MESSAGE, {event: CHALLENGE_ANSWER_CHOICE, payload: payload})
        add(val)
    }

    const unselect = (val: string) => {
        const payload = {...action, ...{state: false}}
        emitter.emit(HTTP_EMIT_MESSAGE, {event: CHALLENGE_ANSWER_CHOICE, payload: payload})
        del(val)
    }

    const ws_handler = (data: any) => {
        const {event, payload} = data

        if(event === SHOW_ANSWER_KEY) {
            if(payload.cid === cid) {
                setOkAnswers((prev) => prev.concat(payload.answers))
            }
        }
    }

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, ws_handler)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, ws_handler)
        }
    }, [])


    React.useEffect(() => {
        if(ok_answers.some((o) => o === ref)){
            setOk(true)
        } else {
            setOk(false)
        }
    }, [ok_answers])

    React.useEffect(() => {
        if(checked) {
            select(ref)
        } else {
            unselect(ref)
        }
    }, [checked])

    return (
        <IonItem
            style={{width: "100%"}} shape="round" color={ok? "success":"primary"}
            disabled={state !== RESPONSE_PENDING} aria-readonly={state !== RESPONSE_PENDING}
            onClick={() => {setChecked(!checked)}}
        >
            <IonLabel style={{fontWeight: 'bold'}} className="ion-text-wrap">
                {label}
            </IonLabel>
            <IonCheckbox slot="start" checked={checked} onClick={() => {setChecked(!checked)}}></IonCheckbox>
        </IonItem>
    )
}


const Proposals: React.FC<ProposalsProps> = (props) => {
    const { items, addAnswer, delAnswer, state, cid, uid } = props

    return (
        <>
            {
                items.map((value: any , index: number) => (
                    <IonRow key={index} className="ion-margin-vertical">
                        <IonCol>
                            <Proposal
                                item={value} add={addAnswer}
                                del={delAnswer} state={state}
                                cid={cid} uid={uid}
                            />
                        </IonCol>
                    </IonRow>
                ))
            }
        </>
    )
}

export default Proposals;