import React from "react";
import { IonButton, IonSpinner } from "@ionic/react";

import { ActionButtonState } from "../interfaces";
import { CHALLENGE_OPENED, RESPONSE_PENDING, RESPONSE_SUBMIT } from "../constants/config";
import i18n from "../i18n/i18n";

const ActionButton: React.FC<ActionButtonState>= (props) => {
    const {challengeState, responseState, answers, processing, onSubmit} = props

    const [loading, setLoading] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false)

    React.useEffect(() => {
        if(challengeState !== CHALLENGE_OPENED) {
            setDisabled(true)
            return
        }
        setDisabled(responseState !== RESPONSE_PENDING || answers.length === 0)
    }, [responseState, answers, challengeState])

    React.useEffect(() => {
        setLoading(processing === true || responseState === RESPONSE_SUBMIT)
    }, [responseState, processing])

    return (
        <IonButton id="action-btn" expand="block" size="large" disabled={disabled} shape="round" fill="solid" onClick={onSubmit} color={'danger'}>
            {loading ? <IonSpinner name="lines" /> : <>{i18n.t('send')}</>} 
        </IonButton>
    )
}

export default ActionButton;