import React from "react";
import Lottie from "lottie-react";

import SuccessAnimation from '../assets/lotties/success.json'
import FailedAnimation from '../assets/lotties/failed.json'
import { RESPONSE_FAILED, RESPONSE_OUTDATED, RESPONSE_SUCCEEDED } from "../constants/config";
import { ResultAnimationState } from "../interfaces";

const ResultAnimation: React.FC <ResultAnimationState> = (props) => {
    const {result, onComplete} = props

    return (
        <>
            {(() => {
                switch (result) {
                    case RESPONSE_SUCCEEDED:
                        return <Lottie animationData={SuccessAnimation} loop={false} onComplete={onComplete} />;
                    case RESPONSE_FAILED:
                    case RESPONSE_OUTDATED:
                        return <Lottie animationData={FailedAnimation} loop={false} onComplete={onComplete} />;
                    default:
                        return null
                }
            })()}
        
        </>
    )
}

export default ResultAnimation;