export const RESPONSE_PENDING: string = 'pending'
export const RESPONSE_SUBMIT: string = 'submit'
export const RESPONSE_OUTDATED: string = 'outdated'
export const RESPONSE_SKIPPED: string = 'skipped'
export const RESPONSE_SUCCEEDED: string = 'succeeded'
export const RESPONSE_FAILED: string = 'failed'

export const CHALLENGE_PENDING: string = 'pending'
export const CHALLENGE_OPENED: string = 'opened'
export const CHALLENGE_CLOSED: string = 'closed'

export const CHALLENGE_ANSWER_CHOICE: string = 'challenge_answer_choice'

export const WS_EMIT_MESSAGE: string = "ws_emit_message"
export const HTTP_EMIT_MESSAGE: string = "http_emit_message"