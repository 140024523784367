/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";
import { IonAlert } from "@ionic/react";

import { format } from "../helpers";
import { UserStore, AppStore, GameStore } from "../stores";
import { ENDPOINTS } from "../constants/api";

export const CheckGame = () => {
    const gid = GameStore((state) => state.gid)
    const resetUser = UserStore((state) => state.resetUser)
    const [url, setURL] = React.useState('')
    const [active, setActive] = React.useState(null)
    const [showAlert, setShowAlert] = React.useState(false)
    const [{data}, call] = useAxios({url: url}, {manual: true})
    const interval = React.useRef<number | null>(null)

    React.useEffect(() => {
        if(gid) {
            const u = format(ENDPOINTS.GAME, [gid])
            setURL(u)
        } else {
            setURL('')
        }
    }, [gid])

    React.useEffect(() => {
        if(url && interval.current === null) {
            interval.current = window.setInterval(() => {
                call()
            }, 10000)
        }

        if(!url && interval.current) {
            window.clearInterval(interval.current)
        }

        return () => {
            if(interval.current) window.clearInterval(interval.current)
        }
    }, [url])

    React.useEffect(() => {
        if(data) {
            setActive(data.is_active)
        }
    }, [data])

    const close = () => {
        setShowAlert(false)
        resetUser()
    }

    return (
        active ? <IonAlert
            isOpen={showAlert}
            onDidDismiss={close}
            header="Alert"
            subHeader="Important message"
            message="This is an alert!"
            buttons={['OK']}
        /> : null
    )

}

export const Ping = () => {
    const userToken = UserStore((state) => state.userToken)
    const setOnline = AppStore((state) => state.setOnline)

    const [{error}, execute] = useAxios(
        {url: ENDPOINTS.PING, method: 'POST'},
        {manual: true}
    )

    const ping = () => {
        if(!userToken) return
        execute()
    }

    React.useEffect(() => {
        if(error) {
            setOnline(false)
        } else {
            setOnline(true)
        }
    }, [error])

    React.useEffect(() => {
        const interval = setInterval(() => {
            ping()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}


export const OnLine = () => {
    const setOnline = AppStore((state) => state.setOnline)

    const check = () => {
        if(!navigator.onLine) {
            setOnline(false)
        } else {
            setOnline(true)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            check()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}