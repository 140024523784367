const TransLation: {[key: string]: any} = {
    signIn: 'Authentification',
    initialization: "Initialisation",
    systemError: "Erreur irrécupérable",
    errorContactSupport: "Veuillez contacter l'équipe support",
    login: {
        title: "Formulaire d'authentification",
        inputs: {
            uid: "Unique ID",
            uidPlaceHolder: 'Entrez votre code identifiant',
            name: "Nom & Prenoms",
            namePlaceHolder: "Entrez vos nom & prenoms",
            missingUID: 'Veuillez entrer votre code identifiant',
            missingName: 'Veuillez renseigner vos nom & prenoms'
        }
    },
    logoutConfirm: {
        title: "Déconnexion",
        msg: "Veuillez confirmer votre demande de déconnexion"
    },
    authError: {
        title: "Erreur d'authentification",
        msg: "Veuillez ré-essayer ou contacter l'équipe support"
    },
    offline_detected: 'Perte de connexion',
    waitChallenge: 'En attente de votre prochain challenge',
    level: 'Niveau',
    expectedAnswerNum: 'Nombre de bonne réponse attendue: %{num}',
    gain: {
        congrats: 'Félicitation, vous avez obtenu: ',
        sorry: "Désolé, vous n'obtenez pas de points.",
        outdated: "Temps écoulé! Désolé, vous n'obtenez pas de points."
    },
    send: 'Envoyer',
    seconds: 'secondes',
    score: 'Score'
}

export default TransLation;