import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton } from '@ionic/react';

import { GameStore } from '../stores';
import Footer from '../components/Footer';
import Game from '../components/Game';
import { AppStore } from '../stores';

import '../theme/Home.css';

const GameScore: React.FC = () => {
  const score = GameStore((state) => state.score)

  return (
    <IonButtons slot="end" className='ion-margin-end'>
      <IonButton className='ion-text-lowercase'>
        Score: {score || 0} pt(s)
      </IonButton>
    </IonButtons>
  )
}

const HomePage: React.FC = () => {
  const appName = AppStore((state) => state.appName)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appName}</IonTitle>
          <GameScore />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{appName}</IonTitle>
            <GameScore />
          </IonToolbar>
        </IonHeader>

        <Game />
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default HomePage;
