/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router";
import {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid,
    IonCol, IonRow, IonAlert, IonIcon, IonItem, IonInput, IonLabel,
    IonButton, IonSpinner, IonText
} from "@ionic/react";
import { lockClosed } from "ionicons/icons";
import useAxios from "axios-hooks";

import Footer from "../components/Footer";
import i18n from "../i18n/i18n";
import { ENDPOINTS } from "../constants/api";
import { AppStore, UserStore, GameStore } from "../stores";

import '../theme/LoginPage.css'

const LoginPage: React.FC = () => {
    const history = useHistory()
    const appName = AppStore((state) => state.appName)
    const setGame = GameStore((state) => state.setGame)
    const {userToken, setUserToken, setUserName, setTags, setUserUID} = UserStore(
        (state) => ({
            userToken: state.userToken,
            setUserToken: state.setUserToken,
            setUserName: state.setUserName,
            setTags: state.setTags,
            setUserUID: state.setUserUID
        })
    )
    const [uid, setUID] = React.useState<string>('')
    const [name, setName] = React.useState<string>('')
    const [iserror, setIserror] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const [{data, loading, error}, call] = useAxios(
        {
            url: ENDPOINTS.JOIN,
            method: 'POST'
        }, {manual: true}
    )

    const login = () => {
        if (!uid) {
            setMessage(i18n.t('login.inputs.missingUID'));
            setIserror(true);
            return;
        }
        if (!name) {
            setMessage(i18n.t('login.inputs.missingName'));
            setIserror(true);
            return;
        }
        call({data: {uid: uid, name: name}})
    }

    React.useEffect(() => {
        if(data) {
            setUserToken(data.token)
            setUserName(data.username)
            setTags(data.tags)
            setUserUID(data.uid)

            setGame({
                title: data.title,
                gid: data.gid,
                category: data.category,
                start: data.start,
                end: data.end,
                is_active: null,
                score: data.score
            })
        }
    }, [data])

    React.useEffect(() => {
        if(userToken !== null) history.push('/')
    }, [userToken])

    React.useEffect(() => {
        if(error) {
            setMessage(i18n.t('authError.msg'));
            setIserror(true);
        }
    }, [error])

    return (
        <IonPage id="login">
            <IonHeader mode='md' translucent>
                <IonToolbar mode='md'>
                    <IonTitle>{appName}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className='ion-padding'>
            <IonGrid fixed>
                    <IonRow>
                        <IonCol>
                            <IonAlert
                                isOpen={iserror}
                                onDidDismiss={() => {setIserror(false)}}
                                header={i18n.t('authError.title')}
                                message={message}
                                buttons={["Dismiss"]}
                                mode='md'
                            />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonRow className='ion-justify-content-center'>
                                <IonIcon
                                    style={{ fontSize: "70px", color: "#ffffff" }}
                                    icon={lockClosed}
                                />
                            </IonRow>
                            
                            <IonText mode='md' className='ion-margin-bottom ion-text-center' style={{color: "white"}}>
                                <h2 style={{marginBottom: 30}}>{i18n.t('login.title')}</h2>
                            </IonText>

                            <IonItem className='ion-margin-bottom' lines='full' mode='md'>
                                <IonLabel position="stacked">{i18n.t('login.inputs.uid')}</IonLabel>
                                <IonInput 
                                    required 
                                    value={uid}
                                    placeholder={i18n.t('login.inputs.uidPlaceHolder')} 
                                    onIonChange={(e) => setUID(e.detail.value!)}
                                />
                            </IonItem>
                            <IonItem className='ion-margin-bottom' lines='full' mode='md'>
                                <IonLabel position="stacked">{i18n.t('login.inputs.name')}</IonLabel>
                                <IonInput
                                    required
                                    placeholder={i18n.t('login.inputs.namePlaceHolder')}
                                    value={name}
                                    onIonChange={(e) => setName(e.detail.value!)}
                                />
                            </IonItem>
                            <IonButton expand="block" onClick={login} mode='md' disabled={loading}>
                                { loading && <IonSpinner name="lines" /> }
                                { !loading && i18n.t('signIn')}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <Footer />
        </IonPage>
    )
}

export default LoginPage;