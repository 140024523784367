import EventEmitter from 'events';

export const emitter = new EventEmitter();

export interface ReactNodeProps {
    children: React.ReactNode;
}

export const format = function(str: any, data: string[]) {
  return str.replace(/{([^{}]+)}/g, function(match: boolean, val: any) {
    var prop: any = data;
    val.split('.').forEach(function(key: number) {
      prop = prop[key];
    });

    return prop;
  });
};