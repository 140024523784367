
export const API_ROOT: string = process.env.NODE_ENV === 'development' ? 'http://api.eqsass.local:8000' : 'https://api.eqsass.com'
export const HEADER_PREFIX: string = "Token"
export const VERSION: string = "1.0"
export const ENDPOINTS: {[key: string]: string} = {
    APP_CONF: '/app-conf/',
    GAME: '/games/{0}/',
    JOIN: '/games/participants/',
    PING: '/users/ping/',
    ACTIONS: '/games/{0}/actions/',
    SUBMIT: '/games/{0}/challenges/{1}/responses/{2}/submit/',
    OUTDATED: '/games/{0}/challenges/{1}/responses/{2}/outdated/',
    SKIPPED: '/games/{0}/challenges/{1}/responses/{2}/skipped/'
}

export const WS_ROOT: string = process.env.NODE_ENV === 'development' ? 'ws://localhost:8000' : 'wss://api.eqsass.com'