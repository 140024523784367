import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage'

import { UserStore } from './stores';

import { OnLine, Ping } from './components/Monitor';
import { LoadAppConf } from './components/LoadAppConf';
import { AxiosProvider } from './providers/axios';
import { WebSocketProvider } from './providers/websocket';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/App.css'

setupIonicReact();

const App: React.FC = () => {
  
  const [isAuth, setIsAuth] = React.useState(false)

  const userToken = UserStore((state) => state.userToken)

  React.useEffect(() => {
    if(userToken) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
  }, [userToken])

  return (
    <IonApp style={{ backgroundColor: '#171031' }}>
      <AxiosProvider>
        <WebSocketProvider>
          <LoadAppConf />
          <IonReactRouter>
            <IonRouterOutlet>
              <Route exact path="/">
                {isAuth ? <HomePage /> : <LoginPage /> }
              </Route>
            </IonRouterOutlet>
          </IonReactRouter>
          {isAuth ? <Ping /> : null}
          <OnLine />
        </WebSocketProvider>
      </AxiosProvider>
    </IonApp>
  )
}

export default App;
