/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useAxios from 'axios-hooks'
import {shallow} from 'zustand/shallow'
import {
    IonGrid, IonRow, IonCol, IonItem, IonLabel
} from "@ionic/react"
import { format } from '../helpers'
import { ENDPOINTS } from '../constants/api'
import Proposals from './Propositions'
import { ChallengeStore, ResponseStore, UserStore } from '../stores'
import { RESPONSE_PENDING, RESPONSE_OUTDATED, RESPONSE_SUBMIT } from "../constants/config";
import i18n from '../i18n/i18n'
import ActionButton from './ActionButton'
import ResultAnimation from './ResultAnimation'
import GainText from './Gain'
import Timer from './Timer'


const Space: React.FC = () => {
    const [suspend, setSuspend] = React.useState<boolean>(false)
    const [submitUrl, setSubmitUrl] = React.useState<string>('')
    const [outdatedUrl, setOutdatedUrl] = React.useState<string>('')
    const [showAnimation, setShowAnimation] = React.useState<boolean>(true)

    const challenge = ChallengeStore((state) => state.challenge)
    const uid = UserStore((state) => state.userUID)
    const [
        rid, repState, answers, score,
        setRepState, addAnswer, delAnswer
    ] = ResponseStore((state) => ([
        state.rid, state.state, state.answers, state.score,
        state.setRepState, state.addAnswer, state.delAnswer
    ]), shallow)

    const [{loading:submitting, data:submitData}, _submit] = useAxios(
        {method: 'patch', url: submitUrl}, {manual: true}
    )
    const [{}, _outdated] = useAxios(
        {method: 'patch', url: outdatedUrl}, {manual: true}
    )

    const onResultAnimationComplete = () => {
        setShowAnimation(false)
    }

    const onTimerComplete = () => {
        // check if response state is pending
        // and move it to outdated which
        // trigger api call
        if(repState === RESPONSE_PENDING) {
            setRepState(RESPONSE_OUTDATED)
        }
    }

    const submit = () => {
        if(!answers.length) return
        _submit({data: {answers: answers}})
    }

    React.useEffect(() => {
        if(challenge && rid) {
            let url;
            url = format(ENDPOINTS.SUBMIT, [challenge.gid, challenge.cid, rid])
            setSubmitUrl(url)
            url = format(ENDPOINTS.OUTDATED, [challenge.gid, challenge.cid, rid])
            setOutdatedUrl(url)
        }
    }, [challenge, rid])

    // set response state to submit
    React.useEffect(() => {
        if(submitData) {
            if(submitData.changed === true) {
                setRepState(submitData.state)
            }
        }
    }, [submitData])

     // suspend timer when response submitted
     React.useEffect(() => {
        if(repState === RESPONSE_SUBMIT) {
            setSuspend(true)
        }
        if(repState === RESPONSE_OUTDATED) {
            _outdated()
        }
    }, [repState])


    return (
        <>
            {
                !challenge ? null :
                <IonGrid>
                    <IonRow>
                        <IonCol size='8'>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel className='ion-text-wrap'>
                                            <h1>{challenge.question}</h1>
                                            <p>{`${i18n.t('level')}: ${challenge.level}`} | {`Gain: ${challenge.gain} pt(s)`} | {i18n.t('expectedAnswerNum', {num: challenge.ok_answers.length})} </p>
                                        </IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <Proposals items={challenge.proposals} addAnswer={addAnswer} delAnswer={delAnswer} state={repState} cid={challenge.cid} uid={uid} />
                        </IonCol>
                        <IonCol size='4'>
                            <IonRow style={{height: "65%"}} className='ion-justify-content-center ion-align-items-center'>
                                <IonCol>
                                    <IonRow>
                                       <IonCol>
                                            {
                                                repState === RESPONSE_PENDING || repState === RESPONSE_SUBMIT ?
                                                    <Timer
                                                        suspend={suspend}
                                                        timespan={challenge.timespan}
                                                        ttl={challenge.ttl}
                                                        onComplete={onTimerComplete}
                                                    /> : 
                                                    <div>
                                                        {
                                                            showAnimation === true && <ResultAnimation result={repState} onComplete={onResultAnimationComplete} />
                                                        }
                                                        {
                                                            showAnimation === false && <GainText result={repState} score={score} />
                                                        }
                                                    </div>
                                            }
                                       </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow style={{height: "35%"}}>
                                <IonCol>
                                    <ActionButton
                                        challengeState={challenge.state}
                                        responseState={repState}
                                        answers={answers}
                                        processing={submitting}
                                        onSubmit={submit}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            }
        </>
    )
}

export default Space;