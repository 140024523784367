/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {shallow} from 'zustand/shallow'
import useAxios from "axios-hooks";

import { format, emitter } from "../helpers";
import { GameStore, ChallengeStore, ResponseStore, UserStore } from "../stores";
import WaitChallenge from "./WaitChallenge";
import Space from "./Space";
import { CLEAN_BOARD, NEW_CHALLENGE, CHALLENGE_USER_SCORE, CHALLENGE_STATE, GAME_USER_SCORE  } from "../constants/ws_event";
import { WS_EMIT_MESSAGE, HTTP_EMIT_MESSAGE } from "../constants/config";
import { ENDPOINTS } from "../constants/api";

import '../theme/Game.css'

const Game: React.FC = () => {
    const [run, setRun] = React.useState(false)

    const gid = GameStore((state) => state.gid)
    const setGameScore = GameStore((state) => state.setScore)
    const userUID = UserStore((state) => state.userUID)
    const [
        challenge, getChallenge, setChallenge, setChallengeState, resetChallenge
    ] = ChallengeStore((state) => ([
        state.challenge, state.getChallenge, state.setChallenge, state.setChallengeState,
        state.resetChallenge
    ]), shallow)

    const [resetResponse, setRID, setRepState, setScore] = ResponseStore((state) => ([
        state.resetResponse, state.setRID,
        state.setRepState, state.setScore
    ]), shallow)

    const [url, setURL] = React.useState<string>('')
    const [{}, call] = useAxios({method: 'post', url: url}, {manual: true})

    const http_notify_action = (data: any) => {
        call({data: data})
    }

    const ws_new_challenge = (data: any) => {
        const {event, payload} = data

        if(event === NEW_CHALLENGE) {
            const challenge = {
                cid: payload.cid,
                gid: payload.gid,
                gain: payload.gain,
                level: payload.level,
                ok_answers: payload.ok_answers,
                proposals: payload.proposals,
                question: payload.question,
                state: payload.state,
                tags: payload.tags,
                timespan: payload.timespan,
                ttl: payload.ttl
            }
            setChallenge(challenge)
            setRID(payload.response.rid)
            setRepState(payload.response.state)
        }
    }

    const ws_handler = (data: any) => {
        const {event, payload} = data

        const _challenge = getChallenge()

        if(event === CLEAN_BOARD) {
            if(payload.gid === gid) {
                setRun(false)
                resetChallenge()
                resetResponse()
            }
        }

        if(event === CHALLENGE_STATE && _challenge) {
            if(payload.cid === _challenge.cid) {
                setChallengeState(payload.state)
            }
        }
        if(event === CHALLENGE_USER_SCORE && _challenge) {
            if(payload.cid === _challenge.cid) {
                setScore(payload.score)
                setRepState(payload.state)
            }
        }
        if(event === GAME_USER_SCORE) {
            if(payload.gid === gid && payload.uid === userUID) {
                setGameScore(payload.score)
            }
        }
    }

    React.useEffect(() => {
        if(gid) {
            const u = format(ENDPOINTS.ACTIONS, [gid])
            setURL(u)
        } else {
            setURL('')
        }
    }, [gid])

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, ws_handler)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, ws_handler)
        }
    }, [])

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, ws_new_challenge)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, ws_new_challenge)
        }
    }, [])

    React.useEffect(() => {
        if(url) emitter.on(HTTP_EMIT_MESSAGE, http_notify_action)
        else {
            emitter.off(HTTP_EMIT_MESSAGE, http_notify_action)
        }

        return () => {
            emitter.off(HTTP_EMIT_MESSAGE, http_notify_action)
        }
    }, [url])


    React.useEffect(() => {
        if(!challenge) {
            setRun(false)
        } else {
            setRun(true)
        }
    }, [challenge])

    return (
        !run ? <WaitChallenge /> : <Space />
    )
}

export default Game;
